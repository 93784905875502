<template>
  <div class="income-expense-list-container">
    <b-row
      v-if="analytic"
      class="match-height"
    >

      <b-col
        cols="6"
        md="4"
      >
        <card-statistic
          :title="t('Tổng thu')"
          :value="analytic.totalIncomeText"
          icon="PlusIcon"
          color="secondary"
        />
      </b-col>

      <b-col
        cols="6"
        md="4"
      >
        <card-statistic
          :title="t('Tổng chi')"
          :value="analytic.totalExpenseText"
          icon="MinusIcon"
          color="secondary"
        />
      </b-col>
      <b-col
        cols="6"
        md="4"
      >
        <card-statistic
          :title="t('Thu - Chi')"
          :value="analytic.deltaText"
          icon="CreditCardIcon"
          color="secondary"
        />
      </b-col>

    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ t('Thu chi') }}</b-card-title>
        <crud-buttons
          :name="t('Thu/Chi')"
          :selected-rows="selectedRows"
          modal="modal-income-expense"
          :show-import="$can('create', 'income-expense')"
          :show-export="true"
          :hide-delete="!$can('delete', 'income-expense')"
          :enable-add="$can('create', 'income-expense')"
          :enable-delete="setDeleteEnable(selectedRows)"
          @on-add="resetItem"
          @on-delete="onDelete"
          @on-export="exportData"
          @on-import-file="importData"
          @download-template-file="downloadImportTemplate"
        >
          <template slot="other-buttons">
            <b-button
              v-if="$can('approve', 'income-expense')"
              v-b-tooltip.hover.v-success
              variant="success"
              class="btn-icon ml-50"
              :title="t('Duyệt')"
              :hidden="setApproveHidden(selectedRows)"
              @click="onApproveClick(selectedRows)"
            >
              <feather-icon icon="CheckSquareIcon" />
            </b-button>
            <b-button
              v-if="$can('approve', 'income-expense')"
              v-b-tooltip.hover.v-secondary
              variant="secondary"
              class="btn-icon ml-50"
              :title="t('Bỏ duyệt')"
              :hidden="setDeclineHidden(selectedRows)"
              @click="onDeclineClick(selectedRows)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </template>
        </crud-buttons>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col v-if="isEnableLocationModule()">
            <select-location
              v-model="location"
              :label="null"
            />
          </b-col>
          <b-col>
            <select-apartment
              v-model="apartment"
              :location="location"
            />
          </b-col>
          <b-col><select-room
            v-model="room"
            :apartment="apartment"
          /></b-col>
          <b-col
            v-if="resolveColWidthIfDisableBed(true) > 0"
          >
            <select-bed
              v-model="bed"
              :room="room"
            />
          </b-col>
          <b-col>
            <select-income-expense v-model="isIncome" />
          </b-col>
          <b-col>
            <select-cashbook v-model="cashbook" />
          </b-col>

        </b-row>
        <b-row>
          <b-col>
            <select-date
              v-model="startDate"
              :placeholder="t('Ngày bắt đầu')"
            />
          </b-col>
          <b-col>
            <select-date
              v-model="endDate"
              :placeholder="t('Ngày kết thúc')"
            />
          </b-col>

          <b-col>
            <select-income-expense-type v-model="type" />
          </b-col>
          <b-col>
            <select-approve-status
              v-model="approveStatus"
              :default-value="approveStatus"
            />
          </b-col>
          <b-col>
            <select-manager
              v-model="manager"
              placeholder="Chọn người thu"
            />
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchTerm"
              :placeholder="t('Tìm kiếm')"
              debounce="1000"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-income-expense-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Amount -->
                <span v-else-if="props.column.field === 'name'">
                  <span>{{ props.row.name }}</span>
                  <small
                    v-if="props.row.note"
                    class="text-muted"
                  ><br>{{ props.row.note }}</small>

                </span>

                <!-- Column: Apartment -->
                <span v-else-if="props.column.field === 'apartment'">
                  <span v-if="props.row.apartment">{{ props.row.apartment.name }}</span>
                  <small
                    v-if="props.row.room"
                    class="text-muted"
                  >
                    <br>{{ props.row.room.name }}
                  </small>
                  <small
                    v-if="props.row.bed"
                    class="text-muted"
                  >
                    <br>{{ props.row.bed.name }}
                  </small>

                </span>

                <!-- Column: Amount -->
                <span v-else-if="props.column.field === 'amountIncome'">
                  <span
                    v-if="props.row.isIncome"
                    class="text-primary"
                  >{{ Number(props.row.amount).toLocaleString() }}</span>

                </span>

                <!-- Column: Amount -->
                <span v-else-if="props.column.field === 'amountExpense'">
                  <span
                    v-if="!props.row.isIncome"
                    class="text-danger"
                  >{{ Number(props.row.amount).toLocaleString() }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'receiver'">
                  {{ props.row.isIncome ? props.row.payer : props.row.receiver }}
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'issueDate'">
                  {{ parseDateToString(props.row.issueDate) }}
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    :name="t('Thu/Chi')"
                    modal="modal-income-expense"
                    :hide-print="false"
                    :disable-delete-reason="props.row.reservation && props.row.reservation.id > 0 ? t('Phiếu thu chi gắn với đặt đặt cọc nên không thể xóa. Để xóa phiếu thu chi này bạn phải xóa đặt đặt cọc') : null"
                    :hide-delete="!$can('delete', 'income-expense')"
                    :hide-edit="!$can('update', 'income-expense') || (props.row.tingeeTransaction && props.row.tingeeTransaction.status === 'unknown')"
                    :disable-delete="props.row.approve"
                    :disable-edit="props.row.approve"
                    @on-delete="onDelete(props.row)"
                    @on-edit="onEditItem(props.row)"
                  >
                    <template
                      slot="other-buttons"
                    >
                      <b-button
                        v-if="props.row.tingeeTransaction && props.row.tingeeTransaction.status === 'unknown'"
                        v-b-tooltip.hover.v-primary
                        v-b-modal.modal-link-invoice
                        variant="primary"
                        class="btn-icon mr-50"
                        :title="t('Liên kết hóa đơn')"
                        size="sm"
                        @click="onEditItem(props.row)"
                      >
                        <feather-icon icon="CheckSquareIcon" />
                      </b-button>
                      <b-button
                        v-if="!props.row.approve && $can('approve', 'income-expense') && !(props.row.tingeeTransaction && props.row.tingeeTransaction.status === 'unknown')"
                        v-b-tooltip.hover.v-primary
                        variant="primary"
                        class="btn-icon mr-50"
                        :title="props.row.tingeeTransaction && props.row.tingeeTransaction.status === 'unknown' ? t('Gắn hóa đơn') : t('Duyệt')"
                        size="sm"
                        @click="onApproveClick([props.row])"
                      >
                        <feather-icon icon="CheckSquareIcon" />
                      </b-button>
                      <b-button
                        v-if="props.row.approve && $can('approve', 'income-expense') && !(props.row.tingeeTransaction && props.row.tingeeTransaction.status === 'unknown')"
                        v-b-tooltip.hover.v-secondary
                        variant="secondary"
                        class="btn-icon mr-50"
                        :title="t('Bỏ duyệt')"
                        size="sm"
                        @click="onDeclineClick([props.row])"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>

                      <b-button
                        v-if="isVMarket"
                        v-b-tooltip.hover.v-info
                        v-b-modal.modal-preview-income-expense
                        variant="info"
                        class="btn-icon mr-50"
                        :title="t('In phiếu')"
                        size="sm"
                        @click="onEditItem(props.row)"
                      >
                        <feather-icon icon="PrinterIcon" />
                      </b-button>

                      <b-button
                        v-if="!isVMarket"
                        v-b-tooltip.hover.v-info
                        variant="info"
                        class="btn-icon mr-50"
                        :title="t('In phiếu')"
                        size="sm"
                        @click="printIncomeExpense(props.row)"
                      >
                        <feather-icon icon="PrinterIcon" />
                      </b-button>

                    </template>
                  </table-actions>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ t('Hiển thị tối đa') }} </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      {{ t('trên tổng số') }} {{ props.total }} {{ t('kết quả') }}
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  {{ t('Không có bản ghi nào!') }}
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <income-expense-modal
      ref="refModalIncomeExpense"
      :item="item"
      @refetch-data="fetchData"
    />

    <income-expense-detail-modal
      :income-expense-id="item.id"
    />
    <preview-income-expense :item="item" />
    <link-invoice-modal
      :item="item"
      @refetch-data="fetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BButton,
  VBTooltip,
  BFormInput,
} from 'bootstrap-vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectBed from '@/views/components/SelectBed.vue';
import SelectIncomeExpenseType from '@/views/components/SelectIncomeExpenseType.vue';
import { VueGoodTable } from 'vue-good-table';
import TableActions from '@/views/components/TableActions.vue';
import CrudButtons from '@/views/components/CrudButtons.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import CardStatistic from '@/views/components/CardStatistic.vue';
import SelectCashbook from '@/views/components/SelectCashbook.vue';
import SelectIncomeExpense from '@/views/components/SelectIncomeExpense.vue';
// eslint-disable-next-line import/no-cycle
import { parseDateToString } from '@/auth/utils';
import SelectApproveStatus from '@/views/components/SelectApproveStatus.vue';
import SelectManager from '@/views/components/SelectManager.vue';
import SelectLocation from '@/views/components/SelectLocation.vue';
import useIncomeExpenseList from './useIncomeExpenseList';
import IncomeExpenseModal from '../modal/IncomeExpenseModal.vue';
import IncomeExpenseDetailModal from '../detail/IncomeExpenseDetailModal.vue';
import PreviewIncomeExpense from '../preview/PreviewIncomeExpense.vue';
import LinkInvoiceModal from '../link-invoice/LinkInvoiceModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BButton,
    BFormInput,
    SelectApartment,
    SelectRoom,
    SelectBed,
    SelectIncomeExpenseType,
    VueGoodTable,
    TableActions,
    CrudButtons,
    IncomeExpenseModal,
    SelectDate,
    CardStatistic,
    SelectCashbook,
    SelectIncomeExpense,
    IncomeExpenseDetailModal,
    SelectApproveStatus,
    PreviewIncomeExpense,
    SelectManager,
    LinkInvoiceModal,
    SelectLocation,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const {
      analytic,
      item,
      columns,
      rows,
      cashbook,
      location,
      apartment,
      room,
      bed,
      status,
      type,
      isIncome,
      startDate,
      endDate,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      approveStatus,
      manager,
      setDeleteEnable,
      fetchData,
      fetchIncomeExpenses,
      deleteIncomeExpenses,
      resetItem,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      printIncomeExpense,
      exportData,
      importData,
      downloadImportTemplate,
      setApproveHidden,
      setDeclineHidden,
      approveIncomeExpense,
      resolveColWidthIfDisableBed,
      t,
      isEnableLocationModule,
    } = useIncomeExpenseList();

    const isVMarket = process.env.VUE_APP_PROVIDER === 'vmarket';

    return {
      isVMarket,
      analytic,
      item,
      columns,
      rows,
      location,
      cashbook,
      apartment,
      room,
      bed,
      status,
      type,
      isIncome,
      startDate,
      endDate,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      approveStatus,
      manager,
      setDeleteEnable,
      fetchData,
      fetchIncomeExpenses,
      deleteIncomeExpenses,
      resetItem,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      printIncomeExpense,
      exportData,
      parseDateToString,
      importData,
      downloadImportTemplate,
      setApproveHidden,
      setDeclineHidden,
      approveIncomeExpense,
      resolveColWidthIfDisableBed,
      t,
      isEnableLocationModule,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    onDelete(incomeExpense) {
      const deleteObjects = incomeExpense && incomeExpense.id > 0 ? [incomeExpense] : this.selectedRows;
      this.deleteIncomeExpenses(deleteObjects);
    },
    onApproveClick(invoices) {
      this.$bvModal
        .msgBoxConfirm(
          `Bạn đang thực hiện thao tác DUYỆT phiếu thu/chi. Bạn có chắc chắn muốn xác nhận duyệt này không?`,
          {
            title: `Duyệt phiếu thu/chi`,
            okTitle: 'Duyệt',
            cancelTitle: 'Huỷ',
            okVariant: 'primary',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(value => {
          if (value) {
            this.approveIncomeExpense(invoices, true);
          }
        });
    },
    onDeclineClick(invoices) {
      this.$bvModal
        .msgBoxConfirm(
          `Bạn đang thực hiện thao tác BỎ DUYỆT phiếu thu/chi. Bạn có chắc chắn muốn hủy duyệt không?`,
          {
            title: `Bỏ duyệt phiếu thu/chi`,
            okTitle: 'Bỏ duyệt',
            cancelTitle: 'Huỷ',
            okVariant: 'warning',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(value => {
          if (value) {
            this.approveIncomeExpense(invoices, false);
          }
        });
    },

  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.income-expense-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

</style>
